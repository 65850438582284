import './home.css';
import '../../assets/housestyle.css';
import React from 'react';
import { motion } from "framer-motion";

const Home = ({ language }) => {

    const translations = {
        en: {
            firstName: 'MANOUK',
            jobTitle: 'technical consultant servicenow',
            contact: 'Contact',
        },
        nl: {
            firstName: 'MANOUK',
            jobTitle: 'technisch consultant servicenow',
            contact: 'Contact',
        },
    };

    const draw = {
        hidden: { pathLength: 0, opacity: 0 },
        visible: (i) => {
          const delay = 1 + i * 0.5;
          return {
            pathLength: 1,
            opacity: 1,
            transition: {
              pathLength: { delay, type: "spring", duration: 1, bounce: 0 },
              opacity: { delay, duration: 0.01 },
            },
          };
        },
      };

    const textFadeIn = {
        hidden: { opacity: 0 },
        visible: (i) => {
            const delay = 1 + i * 0.5 + 0.5; // Adjust delay to match the rect animation
            return {
                opacity: 1,
                transition: {
                    delay,
                    duration: 1.5,
                },
            };
        },
    };

    const handleClick = () => {
        window.open('https://www.linkedin.com/in/manouk-van-draanen-5ba112154', '_blank');
    };

    return (
        <div className="home">
            <div className="intro">
                <div className="first-name">{translations[language].firstName}</div>
                <div className="job-title">{translations[language].jobTitle}</div>
                <motion.svg
                width="150"
                height="40"
                viewBox="0 0 150 40"
                initial="hidden"
                animate="visible"
                onClick={handleClick}
                style={{ cursor: 'pointer' }}
                >
                <motion.rect
                    width="140"
                    height="30"
                    x="5"
                    y="5"
                    rx="10"
                    stroke="#deae00"
                    fill="#deae00"
                    variants={draw}
                    custom={3}
                />
                <motion.text 
                    x="48"
                    y="23"
                    fontSize="14"
                    fontFamily='Quicksand'
                    fill="var(--off-white)"
                    variants={textFadeIn}
                    custom={3}
                >
                    {translations[language].contact}
                </motion.text>
                </motion.svg>
            </div>
        </div>
    );
};

export default Home;
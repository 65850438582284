import React, { useState, useEffect, useRef } from 'react';
import './credentials.css'; // Ensure the case matches the actual file name
import { RiSpeakLine } from "react-icons/ri";
import { TbWriting } from "react-icons/tb";
import { CgReadme } from "react-icons/cg";
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { IoCarOutline } from "react-icons/io5";
import '../../assets/housestyle.css';
import { PiGraduationCapBold } from "react-icons/pi";
import { LiaCertificateSolid } from "react-icons/lia";
import { LuCalendarDays } from "react-icons/lu";

const translations = {
  en: {
    title: 'Credentials',
    education: 'Education',
    certifications: 'Certifications',
    languages: 'Languages',
    technicalSkills: 'Technical Skills',
    otherSkills: 'Other Skills',
    mediaManagement: 'Media & Entertainment Management',
    havo: 'HAVO Nieuwe Stijl',
    cis: 'CIS: IT Service Management',
    cad: 'CAD: Certified Application Developer',
    csa: 'CSA: Certified System Administrator',
    dutch: 'DUTCH',
    english: 'ENGLISH',
    spanish: 'SPANISH',
    native: 'Native',
    fluent: 'Fluent',
    intermediate: 'Intermediate',
    basic: 'Basic',
    amB: 'AM + B',
  },
  nl: {
    title: 'Referenties',
    education: 'Opleiding',
    certifications: 'Certificeringen',
    languages: 'Talen',
    technicalSkills: 'Technische Vaardigheden',
    otherSkills: 'Andere Vaardigheden',
    mediaManagement: 'Media & Entertainment Management',
    havo: 'HAVO Nieuwe Stijl',
    cis: 'CIS: IT Service Management',
    cad: 'CAD: Certified Application Developer',
    csa: 'CSA: Certified System Administrator',
    dutch: 'NEDERLANDS',
    english: 'ENGELS',
    spanish: 'SPAANS',
    native: 'Moedertaal',
    fluent: 'Vloeiend',
    intermediate: 'Gemiddeld',
    basic: 'Basis',
    amB: 'AM + B',
  },
};

function Credentials({ language }) {
  const [progressValues, setProgressValues] = useState({
    javascript: 0,
    react: 0,
    nodejs: 0,
    html: 0,
    css: 0,
  });

  const CredentialsRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
          setProgressValues({
            javascript: 0,
            react: 0,
            nodejs: 0,
            html: 0,
            css: 0,
          });
        }
      },
      { threshold: 0.1 }
    );

    if (CredentialsRef.current) {
      observer.observe(CredentialsRef.current);
    }

    return () => {
      if (observer && CredentialsRef.current) {
        observer.unobserve(CredentialsRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (isVisible) {
      const timeoutIds = [];
      const skills = ['javascript', 'react', 'nodejs', 'html', 'css'];
      const values = [90, 30, 20, 80, 80];

      skills.forEach((skill, index) => {
        const timeoutId = setTimeout(() => {
          setProgressValues(prevValues => ({
            ...prevValues,
            [skill]: values[index],
          }));
        }, index * 500); // Delay each skill's animation
        timeoutIds.push(timeoutId);
      });

      return () => {
        timeoutIds.forEach(timeoutId => clearTimeout(timeoutId));
      };
    }
  }, [isVisible]);

  useEffect(() => {
    const arrowDown = document.querySelector('.arrow-down');
    let lastScrollTop = 0;

    const handleScroll = () => {
      let scrollTop = CredentialsRef.current.scrollTop;
      if (scrollTop > lastScrollTop) {
        // Scrolling down
        arrowDown.style.opacity = '1';
      } else {
        // Scrolling up
        arrowDown.style.opacity = '0';
      }
      lastScrollTop = scrollTop;
    };

    const container = CredentialsRef.current;
    container.addEventListener('scroll', handleScroll);

    return () => {
      container.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="credentials-container" ref={CredentialsRef}>
        <h1>{translations[language].title}</h1>
        <div className="study-section">
            <div className="education-section">
                <div className="education">
                    <span className="icon"><PiGraduationCapBold /></span><span className="content"><h5>{translations[language].mediaManagement}</h5><p>INHolland University of Applied Sciences</p><p className="period"><LuCalendarDays />2011 · 2019</p></span>
                </div>
                <div className="education">
                    <span className="icon"><PiGraduationCapBold /></span><span className="content"><h5>{translations[language].havo}</h5><p>Ashram College</p><p className="period"><LuCalendarDays />2006 · 2010</p></span>
                </div>
            </div>
            <div className="certification-section">
            <div className="certification">
                    <span className="icon"><LiaCertificateSolid /></span><span className="content"><h5>{translations[language].cis}</h5><p>ServiceNow</p><p className="period"><LuCalendarDays />09-2024</p></span>
                </div>
                <div className="certification">
                    <span className="icon"><LiaCertificateSolid /></span><span className="content"><h5>{translations[language].cad}</h5><p>ServiceNow</p><p className="period"><LuCalendarDays />04-2014</p></span>
                </div>
                <div className="certification">
                    <span className="icon"><LiaCertificateSolid /></span><span className="content"><h5>{translations[language].csa}</h5><p>ServiceNow</p><p className="period"><LuCalendarDays />02-2022</p></span>
                </div>
            </div>
        </div>
        <div className="arrow-down"></div>
        <div className="skill-section">
            <div className="languages-section">
                <table className="credentials-table">
                <thead>
                    <tr>
                    <th></th>
                    <th>{translations[language].dutch}</th>
                    <th>{translations[language].english}</th>
                    <th>{translations[language].spanish}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                    <td className="icon"><RiSpeakLine /></td>
                    <td>{translations[language].native}</td>
                    <td>{translations[language].fluent}</td>
                    <td>{translations[language].intermediate}</td>
                    </tr>
                    <tr>
                    <td className="icon"><TbWriting /></td>
                    <td>{translations[language].native}</td>
                    <td>{translations[language].fluent}</td>
                    <td>{translations[language].basic}</td>
                    </tr>
                    <tr>
                    <td className="icon"><CgReadme /></td>
                    <td>{translations[language].native}</td>
                    <td>{translations[language].fluent}</td>
                    <td>{translations[language].basic}</td>
                    </tr>
                </tbody>
                </table>
            </div>
        <div className="technical-credentials-section">
            <div className="technical-credentials">
            <div className="skill">
                <h5>JavaScript</h5>
                <CircularProgressbar 
                value={progressValues.javascript} 
                text={`${progressValues.javascript}%`} 
                styles={{
                    path: { stroke: 'var(--detail-color)' }, // Use CSS variable
                    text: { fill: '#FFF' }, // Use CSS variable
                    trail: { stroke: 'var(--outline-color)'}
                }} 
                />
            </div>
            <div className="skill">
                <h5>React</h5>
                <CircularProgressbar 
                value={progressValues.react} 
                text={`${progressValues.react}%`} 
                styles={{
                    path: { stroke: 'var(--detail-color)' }, // Use CSS variable
                    text: { fill: '#FFF' }, // Use CSS variable
                    trail: { stroke: 'var(--outline-color)'} // Change the outline color
                }} 
                />
            </div>
            <div className="skill">
                <h5>Node.js</h5>
                <CircularProgressbar 
                value={progressValues.nodejs} 
                text={`${progressValues.nodejs}%`} 
                styles={{
                    path: { stroke: 'var(--detail-color)' }, // Use CSS variable
                    text: { fill: '#FFF' }, // Use CSS variable
                    trail: { stroke: 'var(--outline-color)' } // Change the outline color
                }} 
                />
            </div>
            <div className="skill">
                <h5>HTML</h5>
                <CircularProgressbar 
                value={progressValues.html} 
                text={`${progressValues.html}%`} 
                styles={{
                    path: { stroke: 'var(--detail-color)' }, // Use CSS variable
                    text: { fill: '#FFF' }, // Use CSS variable
                    trail: { stroke: 'var(--outline-color)' } // Change the outline color
                }} 
                />
            </div>
            <div className="skill">
                <h5>CSS</h5>
                <CircularProgressbar 
                value={progressValues.css} 
                text={`${progressValues.css}%`} 
                styles={{
                    path: { stroke: 'var(--detail-color)' }, // Use CSS variable
                    text: { fill: '#FFF' }, // Use CSS variable
                    trail: { stroke: 'var(--outline-color)' } // Change the outline color
                }} 
                />
            </div>
            </div>
        </div>
      </div>
      <div className="other-credentials-section">
        <div className="other-credentials">
          <div className="skill">
        <div className="skill-value">
          <span className="icon"><IoCarOutline /></span>{translations[language].amB}
        </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Credentials;
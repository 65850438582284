import './App.css';
import './assets/housestyle.css';
import { useRef, useMemo, useState, useEffect } from 'react';
import Home from './components/home/home';
import Identity from './components/identity/identity';
import Journey from './components/journey/journey';
import Credentials from './components/credentials/credentials';
import { CiGlobe } from "react-icons/ci";
/*import Creations from './components/creations/creations';*/

/* gamified menu items: prologue - character - journey - skilltree - trophies */

const sections = {
  home: Home,
  identity: Identity,
  journey: Journey,
  credentials: Credentials,
  /*creations: Creations,*/
};

function App() {
  const [visibleSection, setVisibleSection] = useState(null);
  const [language, setLanguage] = useState('en'); // State to track the current language
  const [buttonPosition, setButtonPosition] = useState({ x: 1350, y: 550 });
  const [dragging, setDragging] = useState(false);
  const homeRef = useRef(null);
  const identityRef = useRef(null);
  const journeyRef = useRef(null);
  const credentialsRef = useRef(null);
/*  const creationsRef = useRef(null); */
  const sectionRefs = useMemo(() => ({
    home: homeRef,
    identity: identityRef,
    journey: journeyRef,
    credentials: credentialsRef,
    /* creations: creationsRef, */
  }), []);
  const scrollToSection = (sectionId) => {
    const sectionRef = sectionRefs[sectionId];
    if (sectionRef.current) {
      sectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const toggleLanguage = () => {
    setLanguage((prevLanguage) => (prevLanguage === 'en' ? 'nl' : 'en'));
  };
  const handleMouseDown = (e) => {
    setDragging(true);
  };
  const handleMouseMove = (e) => {
    if (dragging) {
      setButtonPosition({
        x: e.clientX - 25, // Adjust for button size
        y: e.clientY - 25,
      });
    }
  };
  const handleMouseUp = () => {
    setDragging(false);
  };

  useEffect(() => {
    const updateButtonPosition = () => {
      if (window.innerWidth <= 768) {
        setButtonPosition({ x: 320, y: 580 }); // Set position for mobile devices
      } else {
        setButtonPosition({ x: 1450, y: 600 }); // Set position for desktop
      }
    };

    updateButtonPosition(); // Set initial position
    window.addEventListener('resize', updateButtonPosition); // Update position on resize

    return () => {
      window.removeEventListener('resize', updateButtonPosition);
    };
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setVisibleSection(entry.target.id);
          }
        });
      },
      { threshold: 0.1 }
    );
    Object.values(sectionRefs).forEach((ref) => {
      if (ref.current) {
        observer.observe(ref.current);
      }
    });
    return () => {
      Object.values(sectionRefs).forEach((ref) => {
        if (ref.current) {
          observer.unobserve(ref.current);
        }
      });
    };
  }, [sectionRefs]);

  useEffect(() => {
    const appElement = document.querySelector('.App');
    const appBodyElement = document.querySelector('.App-body');
    if (visibleSection !== 'home') {
      appElement.classList.add('fade-out-background');
      appBodyElement.classList.add('App-body-background');
    } else {
      appElement.classList.remove('fade-out-background');
      appBodyElement.classList.remove('App-body-background');
    }
  }, [visibleSection]);

  const translations = {
    en: {
      home: 'Home',
      identity: 'Identity',
      journey: 'Journey',
      credentials: 'Credentials',
      clickToTranslate: 'Vertaal naar Nederlands',
      // Add more translations as needed
    },
    nl: {
      home: 'Thuis',
      identity: 'Identiteit',
      journey: 'Reis',
      credentials: 'Kwalificaties',
      clickToTranslate: 'Translate to English',
      // Add more translations as needed
    },
  };

  return (
    <div className="App" onMouseMove={handleMouseMove} onMouseUp={handleMouseUp}>
      <header className="App-header">
        <div className="App-header-gradient"></div>
        <nav className="App-nav">
          <ul>
            {Object.keys(sections).map((sectionId) => (
              <li key={sectionId}>
                <a
                  href={`#${sectionId}`}
                  data-text={sectionId}
                  className={visibleSection === sectionId ? 'active' : ''}
                  onClick={(e) => { e.preventDefault(); scrollToSection(sectionId); }}
                >
                  {translations[language][sectionId]}
                </a>
              </li>
            ))}
          </ul>
        </nav>
      </header>
      <button
        title={translations[language].clickToTranslate}
        onClick={toggleLanguage}
        className="language-toggle"
        onMouseDown={handleMouseDown}
        style={{
          position: 'fixed',
          top: `${buttonPosition.y}px`,
          left: `${buttonPosition.x}px`,
          zIndex: 1000,
          cursor: dragging ? 'grabbing' : 'grab',
        }}
      >
        <CiGlobe />
      </button>
      <div className="App-body">
        {Object.keys(sections).map((sectionId) => {
          const Component = sections[sectionId];
          const isVisible = visibleSection === sectionId;
          return (
            <section
              id={sectionId}
              ref={sectionRefs[sectionId]}
              key={sectionId}
              className={isVisible ? 'fade-in' : 'fade-out'}
            >
              <Component language={language} />
            </section>
          );
        })}
      </div>
    </div>
  );
}

export default App;
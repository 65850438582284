import React from 'react';
import './identity.css';
import { LuBookOpen } from "react-icons/lu";
import { TbBrandGoogleAnalytics } from "react-icons/tb";
import { RiTeamLine } from "react-icons/ri";

const Identity = ({ language }) => {

    const translations = {
        en: {
            title: 'Identity',
            introduction: `As a Technical Consultant, my role combines both technical skills and strong people skills. I believe my strength is in bringing these together to create solutions that are both effective and client-focused.
            Outside of consulting, I’m a tech enthusiast at heart and love diving into creative projects—whether I’m brainstorming a new app idea or refining my tech stack. I think every project adds something unique to my journey and helps me grow along the way.
            Some of my core strengths are:`,
            analytical: 'Analytical',
            autodidactic: 'Autodidactic',
            teamplayer: 'Teamplayer',
            yearsOfExp: 'years of ServiceNow Experience',
            nrOfCert: 'ServiceNow Certifications',
        },
        nl: {
            title: 'Identiteit',
            introduction: `Als Technisch Consultant combineer ik technische vaardigheden met sterke sociale vaardigheden. Ik geloof dat mijn kracht ligt in het samenbrengen van deze elementen om oplossingen te creëren die zowel effectief als klantgericht zijn.
            Buiten het adviseren ben ik een tech-enthousiasteling in hart en nieren en duik ik graag in creatieve projecten—of ik nu een nieuw app-idee bedenk of mijn tech-stack verfijn. Ik denk dat elk project iets unieks toevoegt aan mijn reis en me helpt groeien.
            Enkele van mijn kernkwaliteiten zijn:`,
            analytical: 'Analytisch',
            autodidactic: 'Autodidactisch',
            teamplayer: 'Teamspeler',
            yearsOfExp: 'jaar ServiceNow-ervaring',
            nrOfCert: 'ServiceNow-certificeringen',
        },
    };

    return (
        <div className="identity">
            <h1>{translations[language].title}</h1>
            <p className="introduction">{translations[language].introduction}</p>
            <ul className="core-strengths">
                <li><span className="icon"><TbBrandGoogleAnalytics /></span><p>{translations[language].analytical}</p></li>
                <li><span className="icon"><LuBookOpen /></span><p>{translations[language].autodidactic}</p></li>
                <li><span className="icon"><RiTeamLine /></span><p>{translations[language].teamplayer}</p></li>
            </ul>
            <br/>
            <div className="cards">
                <div className="years-of-exp"><h1>4+</h1><p>{translations[language].yearsOfExp}</p></div>
                <div className="nr-of-cert"><h1>3</h1><p>{translations[language].nrOfCert}</p></div>    
            </div>
        </div>
    );
};

export default Identity;
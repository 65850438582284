import React, { useState, useEffect } from 'react';
import './journey.css';
import { LuCalendarDays } from "react-icons/lu";

const events = {
  en: [
    { year: 2020, period: '02-2020 · 04-2021', company: 'Peak-IT', city: 'Naarden, Netherlands', jobTitle: 'Servicedesk Engineer', details: '<p class="details-desc">As a ServiceDesk Engineer, I was responsible for providing comprehensive technical support across a broad spectrum of software and hardware systems. Additionally, I contributed to the development and enhancement of the ServiceNow instance, ensuring it aligned with business needs and improved operational efficiency.</p>', order: 4, marginTop: '-170px' },
    { year: 2021, period: '11-2021 · 09-2024', company: 'Cegeka', city: 'Zoetermeer, Netherlands', jobTitle: 'Technical Consultant ServiceNow', details: '<p class="details-desc">As a Technical Consultant, my role was to design, develop and implement custom solutions on the ServiceNow platform to align with business needs and best practices. I was part of a DevOps team responsible for implementing the Telecommunications, Media & Technology (TSM) module for B2B Order Management at KPN.<br/><br/>Key responsibilities included:<ul class="details-list"><li>Developing and integrating system solutions</li><li>Designing detailed technical specifications</li><li>Enhancing and optimizing platform features</li><li>Resolving incidents</li></ul>', order: 5, marginTop: '-80px' },
    { year: 2021, period: '04-2021 · 11-2021', company: 'Solimas', city: 'Amsterdam, Netherlands', jobTitle: 'IT Support Specialist', details: '<p class="details-desc">As an IT Support Specialist, I was responsible for delivering technical support across a diverse range of software, hardware, and network systems, ensuring efficient and effective troubleshooting to maintain optimal functionality and performance.</p>', order: 6, marginTop: '-130px' },
    { year: 2024, period: '10-2024 · now', company: 'Devoteam', city: 'Amsterdam, Netherlands', jobTitle: 'Technical Consultant ServiceNow', details: '<p class="details-desc">As a Technical Consultant, my role is to design, develop and implement custom solutions on the ServiceNow platform to align with business needs and best practices.<br/><br/>Key responsibilities include: <ul class="details-list"><li>Configuring workflows</li><li>Building integrations</li><li>Creating technical designs</li><li>Data management</li><li>Resolving incidents</li></ul></p>', order: 7, marginTop: '0px' },
  ],
  nl: [
    { year: 2020, period: '02-2020 · 04-2021', company: 'Peak-IT', city: 'Naarden, Netherlands', jobTitle: 'Servicedesk Engineer', details: '<p class="details-desc">Als ServiceDesk Engineer was ik verantwoordelijk voor het bieden van uitgebreide technische ondersteuning voor een breed scala aan software- en hardwaresystemen. Daarnaast droeg ik bij aan de ontwikkeling en verbetering van de ServiceNow-instantie, zodat deze aansloot bij de bedrijfsbehoeften en de operationele efficiëntie verbeterde.</p>', order: 4, marginTop: '-170px' },
    { year: 2021, period: '11-2021 · 09-2024', company: 'Cegeka', city: 'Zoetermeer, Netherlands', jobTitle: 'Technisch Consultant ServiceNow', details: '<p class="details-desc">Als Technisch Consultant was het mijn taak om op maat gemaakte oplossingen te ontwerpen, ontwikkelen en implementeren op het ServiceNow-platform om aan de bedrijfsbehoeften en best practices te voldoen. Ik maakte deel uit van een DevOps-team dat verantwoordelijk was voor de implementatie van de Telecommunications, Media & Technology (TSM) module voor B2B Order Management bij KPN.<br/><br/>Belangrijkste verantwoordelijkheden waren:<ul class="details-list"><li>Systeemoplossingen ontwikkelen / integreren</li><li>Technische specificaties ontwerpen</li><li>Platformfuncties verbeteren / optimaliseren</li><li>Incidenten oplossen</li></ul>', order: 5, marginTop: '-80px' },
    { year: 2021, period: '04-2021 · 11-2021', company: 'Solimas', city: 'Amsterdam, Netherlands', jobTitle: 'IT Support Specialist', details: '<p class="details-desc">Als IT Support Specialist was ik verantwoordelijk voor het leveren van technische ondersteuning voor een breed scala aan software-, hardware- en netwerksystemen, waarbij ik zorgde voor efficiënte en effectieve probleemoplossing om optimale functionaliteit en prestaties te behouden.</p>', order: 6, marginTop: '-130px' },
    { year: 2024, period: '10-2024 · nu', company: 'Devoteam', city: 'Amsterdam, Netherlands', jobTitle: 'Technisch Consultant ServiceNow', details: '<p class="details-desc">Als Technisch Consultant is het mijn taak om op maat gemaakte oplossingen te ontwerpen, ontwikkelen en implementeren op het ServiceNow-platform om aan de bedrijfsbehoeften en best practices te voldoen.<br/><br/>Belangrijkste verantwoordelijkheden zijn: <ul class="details-list"><li>Configureren van workflows</li><li>Bouwen van integraties</li><li>Maken van technische ontwerpen</li><li>Gegevensbeheer</li><li>Oplossen van incidenten</li></ul></p>', order: 7, marginTop: '0px' },
  ],
};

const sortedEvents = (language) => events[language].sort((a, b) => b.order - a.order);

const adjustSolimasPosition = (events) => {
  const solimasIndex = events.findIndex(event => event.company === 'Solimas');
  if (solimasIndex !== -1 && solimasIndex < events.length - 1) {
    const temp = events[solimasIndex];
    events[solimasIndex] = events[solimasIndex + 1];
    events[solimasIndex + 1] = temp;
  }
  return events;
};

const Journey = ({ language }) => {
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [animationClass, setAnimationClass] = useState('');
  const [activeEvent, setActiveEvent] = useState(null);

  const translations = {
    en: {
      title: 'Journey',
      clickToFindOutMore: 'Click me to find out more!',
    },
    nl: {
      title: 'Reis',
      clickToFindOutMore: 'Klik hier om meer te weten te komen!',
    },
  };

  useEffect(() => {
    const timelineEvents = document.querySelectorAll('.timeline-event');
    const verticalLine = document.querySelector('.vertical-line');
    if (activeEvent) {
      timelineEvents.forEach(e => e.classList.add('decreased-padding'));
      timelineEvents.forEach(e => {
        if (e !== activeEvent) {
          e.classList.add('fade-out-others');
        }
      });
      verticalLine.classList.add('fade-out-others');
    } else {
      timelineEvents.forEach(e => e.classList.remove('decreased-padding'));
      timelineEvents.forEach(e => e.classList.remove('fade-out-others'));
      verticalLine.classList.remove('fade-out-others');
    }
  }, [activeEvent]);

  const handleEventClick = (event) => {
    if (activeEvent === event) {
      setActiveEvent(null);
      setSelectedEvent(null);
    } else {
      setAnimationClass('fade-out');
      setTimeout(() => {
        setSelectedEvent(event);
        setActiveEvent(event);
        setAnimationClass('fade-in');
      }, 500); // Duration of the fade-out animation
    }
  };

  useEffect(() => {
    if (activeEvent) {
      document.documentElement.style.setProperty('--mobile-margin-top', activeEvent.marginTop);
    }
  }, [activeEvent]);

  const adjustedEvents = adjustSolimasPosition(sortedEvents(language));

  return (
    <div className="journey-container">
      <h1>{translations[language].title}</h1>
      <div className="journey">
        <div className="timeline-container">
          <div className="vertical-line"></div>
          {adjustedEvents.map((event, index) => (
            <div
              className={`timeline-event ${activeEvent === event ? 'active' : ''}`}
              key={index}
              onClick={() => handleEventClick(event)}
            >
              <div className="left">
                {event.company !== 'Cegeka' && <h3>{event.year}</h3>}
              </div>
              <div className="dot"></div>
              <div className="right" title={translations[language].clickToFindOutMore}>
                <h4>{event.company}</h4>
                <p>{event.jobTitle}</p>
                <p className="event-period"><LuCalendarDays />{event.period}</p>
                <div className="event-details" dangerouslySetInnerHTML={{ __html: event.details }} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Journey;